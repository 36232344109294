import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { InvoicesStateModel, InvoicesStateTypes } from './invoices-state.model';
import { InvoiceHistoryTogglePanelAction } from './invoice-history-toggle-panel.action';

@State<InvoicesStateModel>({
  name: 'invoices',
  defaults: {
    historyOpened: [],
    version: 1,
  },
})
@Injectable()
export class InvoicesState {
  static historyOpened(type: InvoicesStateTypes) {
    return createSelector([InvoicesState], ({ historyOpened }: InvoicesStateModel) => historyOpened.includes(type));
  }

  @Action(InvoiceHistoryTogglePanelAction)
  historyTogglePanel({ setState, getState }: StateContext<InvoicesStateModel>, { panel }: InvoiceHistoryTogglePanelAction) {
    const state = getState();
    const historyOpened = [...state.historyOpened];
    const index = historyOpened.indexOf(panel);
    if (index > -1) {
      historyOpened.splice(index, 1);
    } else {
      historyOpened.push(panel);
    }
    setState({ ...state, historyOpened });
  }
}
