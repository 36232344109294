import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DashboardPanelTypes, DashboardStateModel } from './dashboard-state.model';
import { DashboardTogglePanelAction } from './dashboard-toggle-panel.action';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    opened: [],
    version: 1,
  },
})
@Injectable()
export class DashboardState {
  static opened(type: DashboardPanelTypes) {
    return createSelector([DashboardState], ({ opened }: DashboardStateModel) => opened.includes(type));
  }

  @Action(DashboardTogglePanelAction)
  dashboardTogglePanel({ setState, getState }: StateContext<DashboardStateModel>, { panel }: DashboardTogglePanelAction) {
    const state = getState();
    const opened = [...state.opened];
    const index = opened.indexOf(panel);
    if (index > -1) {
      opened.splice(index, 1);
    } else {
      opened.push(panel);
    }
    setState({ ...state, opened });
  }
}
